<template>
  <div class="project-card-container" @click="cursorAction">
    <div
      class="flex justify-center"
      v-if="src.projectsConfig?.images?.image?.mediaItemUrl"
    >
      <a
        v-if="src.projectsConfig?.externalLink"
        :href="src.projectsConfig?.externalLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        <NuxtImg
          :src="src.projectsConfig?.images?.image?.mediaItemUrl"
          class="logo-projects"
          sizes="md:390px"
        />
      </a>
      <div
        v-else-if="src.projectsConfig?.internalPost"
        class="hover"
        @click="
          navigateTo({
            path: localePath({
              name: 'article-id',
              params: { id: src.projectsConfig?.internalPost.slug },
            }),
          })
        "
      >
        <NuxtImg
          :src="src.projectsConfig?.images?.image?.mediaItemUrl"
          class="logo-projects"
          sizes="md:390px"
        />
      </div>
      <NuxtImg
        v-else
        :src="src.projectsConfig?.images?.image?.mediaItemUrl"
        class="logo-projects"
        sizes="md:390px"
      />
    </div>
    <div class="p-2 md:p-8">
      <NuxtImg
        v-if="src.projectsConfig?.images?.logo?.mediaItemUrl"
        :src="src.projectsConfig?.images?.logo?.mediaItemUrl"
        class="logo-projects-logo"
      />
      <p v-if="src.projectsConfig?.content" class="mt-6 text-left">
        {{ src?.projectsConfig?.content }}
      </p>
      <!-- <a
        v-if="src.projectsConfig?.externalLink"
        class="mt-4 inline-block pointer-events-auto whitespace-nowrap bg-transparent hover:bg-black hover:text-white border rounded-full border-black px-4 py-2 text-sm"
        :href="src.projectsConfig?.externalLink"
        target="_blank"
      >
        {{ $t("read-more") }}
      </a>
      <div v-if="src.projectsConfig?.internalPost">
        <button
          class="mt-4 inline-block pointer-events-auto whitespace-nowrap bg-transparent hover:bg-black hover:text-white border rounded-full border-black px-4 py-2 text-sm"
          @click="
            navigateTo({
              path: localePath({
                name: 'article-id',
                params: { id: src.projectsConfig?.internalPost.slug },
              }),
            })
          "
        >
          {{ $t("read-more") }}
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectCard",
  props: {
    src: {
      type: Object,
    },
  },
  methods: {
    cursorAction() {
      if (
        this.src.projectsConfig?.posts &&
        this.src.projectsConfig?.posts?.length > 0
      ) {
        return navigateTo(
          this.localePath({
            name: "article-id",
            params: { id: this.src.projectsConfig?.posts[0].slug },
          })
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.project-card-container {
  min-width: 400px;

  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
}

.hover {
  cursor: pointer;
}

.logo-projects {
  width: 387px;
  height: 290px;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    //padding: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
    height: 187px;
    padding-right: 2rem;
    /* height: 100%; */
  }
  @media screen and (max-width: 500px) {
    //padding: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
    height: 187px;
    padding-right: 2rem;
    /* height: 100%; */
  }
}

.logo-projects-logo {
  max-height: 26px;
  height: 26px;
}
</style>
